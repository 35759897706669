
.cursorLaunch{ // Deuxième solution : Comment
    width: 1.3rem;
    height: 60%;
    background-color: black;
} 

.consoleCursor{
    display: inline-block;
    margin-left: 0.3rem;
    width: 0.5rem;
    height: 1rem;
    background-color: rgba(255, 255, 255, 0.762);
    vertical-align:sub;
}

