div.cvUnfocus{
    // background-color: rgba(0, 0, 255, 0.18); //
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;


    img{
        outline: 1px solid black;
        position: relative;
        // top: 2rem;
        width: 50%;
        height: auto;
        transition: opacity 0.5s ease;
        opacity: 0.8;

        &:hover{
            cursor: zoom-in;
            opacity: 1;
        }
    }
}



div.cvFocusOverlay{
    // background-color: rgba(0, 0, 0, 0.008); //
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: opacity-in 0.5s linear forwards;

    &:hover{
        cursor: zoom-out;
    }

    div.cvFocusBox{
        outline: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 90%;
        width: 60%;
        opacity: 0;
        animation: opacity-in 0.5s linear forwards;

        iframe{
            outline: 0.3rem solid black;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
        }
    }
}

