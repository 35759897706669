div.impulseBackgroundShadow{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(225, 225, 225);
    top: 0;
    left: 0;
    transition: all 0.3s linear;


    div.impulseBackgroundImage{
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("/src/Assets/background.png");
        background-position-y:center;
        background-position-x: center;
        opacity: 0.02;
        transition: opacity 0.3s linear ;
    }

    div.impulseActivationBackgroundImage{
        opacity: 0.04;
    }
}

div.overlayCV{
    background-color: rgb(174, 174, 174);
    z-index: 0;
}