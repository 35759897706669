.shortDescriptionDisplay{
    display: flex;
    align-items: center;
    padding: 1rem;
    // background-color: rgba(255, 255, 0, 0.181); //
    overflow: hidden;

    >.shortDescriptionBox{
        height: 100%;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        >p{
            padding-top: 2rem;
            padding-right: 2rem;
            // outline: 1px solid green; //
            width: 100%;
            height: 80%;
            max-height: 80%;
            font-size: 1.7vh;
            font-family: inconsolata;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 1rem;
            font-weight: 400;
            position: relative;
            overflow-y: auto;
            overflow-x: hidden;
            color: black;
            text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.801), 5px 5px 1px rgba(0, 0, 0, 0.042);
            @media(max-width:1150px){
                padding-top: 0;
                padding-right: 1rem;
                font-size: 1.4vh;
            }
            
    
            >span{
                
                position: relative;
                opacity: 0;
            }

            

            span:nth-child(1){font-size: 2.7vh;animation: opacity-in 1s linear forwards;
                @media(max-width:1150px){
                    font-size: 1.8vh;
                }
            }
            span:nth-child(2){animation: opacity-in 1s 0.4s linear forwards;}
            span:nth-child(3){animation: opacity-in 1s 0.8s linear forwards;}
            span:nth-child(4){animation: opacity-in 1s 1.2s linear forwards;}
            span:nth-child(5){animation: opacity-in 1s 1.6s linear forwards;}
            span:nth-child(6){animation: opacity-in 1s 2s linear forwards;}
            span:nth-child(7){animation: opacity-in 1s 2.4s linear forwards;}
        }
    }
}

