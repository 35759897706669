.projetDisplay{
    // background-color: rgba(255, 0, 0, 0.178); //
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    // opacity: 0; //
    // animation: opacity-in 1s ease forwards; //
    overflow-x: hidden;
    overflow-y: visible;
    z-index: 4000;

    @media(max-width:425px){
        align-items: flex-start;
    }

    .projetBox{
        // outline: 1px solid rgba(80, 210, 33, 0.739); //
        width: 80%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1rem;
        position: relative;

        // @media(max-width:425px){
        //     width: 95%;
        //     height: 95%;
        // }
    }

    
}

