.navBox{
    @media(max-width:1150px){
        // background-color: blue; //
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;

        .hexagon{
            position: relative;
            top: 0;
            left: 0;

            svg{
                width: 6rem;
                height: 6rem;

                @media(max-width:425px){
                    width: 4rem;
                    height: 4rem;
                }
            }

            .hexagonSentenceBox{

                .hexagonName{
                    font-size: 1.3rem;
                    @media(max-width:425px){
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}