div.mainDisplay{
    // opacity: 0;
    // animation: opacity-in 0.5s ease forwards;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content:center;
    align-items: center;
    position: relative;
    

    .mainBox{
        width: 100%;
        height: 100%;
        // background-color: grey; //
        display: grid;
        grid-template-columns: 20% 80%;
    }
}











    
    
    
    