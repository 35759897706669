.profilOverlay{
    // background-color: rgba(124, 215, 44, 0.44); //
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    .profilBox{
        width: 95%;
        height: 100%;
        // outline: 1px solid rgb(52, 52, 52); //
        // background-color: rgba(255, 255, 255, 0.45); //
        display: grid;
        grid-template-rows: 50% 50%;
        grid-template-columns: 1fr;
        // overflow: auto;
        

        div.presentationBox{
            // background-color: rgba(255, 0, 0, 0.24); //
            position: relative;
            display: grid;
            grid-template-columns: 70% 30%;
            // overflow: auto;

            @media(max-width:1150px){
                grid-template-columns: 80% 20%;
            }
        }

        .languagePresentationBox{
            // background-color: rgba(0, 128, 0, 0.24); //
            // position: relative; //
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        &::-webkit-scrollbar{
            width: 8px;
            background: black;
        }
    
        &::-webkit-scrollbar-thumb{
            background: rgba(255, 255, 255, 0.517);
            border-radius: 50px;
            font-size: 10px;
        }
    }
}


