.languagesLevelBox{
    width: 100%;
    height: fit-content;
    // max-height: 100%;
    // background-color: rgba(250, 38, 38, 0.538); //
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    align-self: center;
    
    animation: opacity-in 0.5s linear forwards;
    @media(max-width:500px){
        max-height: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3,1fr);
    }
    
    

    >div.languageBox{
        
        // outline: 1px solid red; //
        display: grid;
        grid-template-rows: min-content 1fr;
        position: relative;
        padding-bottom: 2rem;
        // background-color: rgba(0, 0, 255, 0.266); //
        @media(max-width:500px){
            padding-bottom: 4rem;
        }

        >div.title{
            height: fit-content;
            // background-color: rgba(0, 255, 255, 0.226); //
            display: flex;
            font-size: 0.8rem;
            font-family: "inconsolata";
            padding: 1rem;
            position: relative;
            color: black;
        }

        >div.languagesList{
            // background-color: pink; // 
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            height: fit-content;

            >div.language{
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                
                >div.languageName{
                    // outline: 1px solid blue; //
                    font-family: "Playfair Display", serif;
                    font-family: "inconsolata";
                    color: black;
                    font-weight: 500;
                    text-shadow: 0px 0px 1px black;
                    font-size: 1.2rem;
                }
    
                >div.languageLevelBox{
                    display: flex;
                    background-color: rgb(44, 44, 44);
                    width: 80%;
                    border-radius: 5px;
                }
            }
            
        }
        >svg{
            width: 100%;
            height: 100%;
            position: absolute;
            // outline: 1px solid red; //
            top: 0;
            left: 0;
            stroke-dashoffset: 100%;
            fill: transparent;
            

            circle{
                fill: black;
                position: absolute;
                z-index: 20;
            }

            polyline{
                stroke-width: 1.2;
                stroke: black;
                stroke-linecap: round;
            }

            .impulseHyperActivation{
                width: fit-content;
                // fill: transparent;
                stroke: rgb(0, 0, 0);
                stroke-linecap: round;
                stroke-width: 3;
                animation: impulseHyperActivationProfil 0.2s linear infinite;
            }
        }
    }

    
}

