.renderBox{
    // background-color: rgba(0, 0, 255, 0.155); //
    // outline: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 1;

    



    *::-webkit-scrollbar{
        width: 8px;
        background: transparent;
    }

    *::-webkit-scrollbar-thumb{
        background: rgb(117, 117, 117);
        border-radius: 50px;
        font-size: 10px;
    }
    canvas{
        background-color: transparent;
    }
}

.renderIn{
    animation: opacity-in 1s ease forwards !important;
}

.renderOut{
    animation: opacity-out 0.5s ease forwards !important;
}