.hexagon{
    // outline: 1px solid red; //

    &:hover{
        cursor: pointer;
    }

    &:hover svg{
        transform: scale(1.1) !important;
        polygon{
            stroke: rgb(51, 51, 51);
            stroke-width: 10;
            fill: rgb(238, 238, 238);
        }
    }

    position: absolute;
    top: 25%;
    left: 20%;
    // outline: 1px solid red; //
    border-radius: 100%; //

    svg{
        // outline: 1px solid red; //
        border-radius: 100%; //
        stroke: rgb(51, 51, 51);
        stroke-width: 6;
        fill:rgb(230, 230, 230);
        transition: all 0.2s ease;
        width: 7rem;
        height: 7rem;

        
    }

    svg.svgSelected{
        polygon{
            transition: fill 0.1s linear;
        }
    }


    
    
    .hexagonSentenceBox{
        // outline: 1px solid red; //
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .hexagonName{
            z-index: 100;
            // height: 2rem;
            // outline: 1px solid green; //
            color: black;
            text-shadow: 0px 0px 1px black;
            text-decoration: none;
            font-size: 1.4rem;
            font-family: inconsolata;
        }
    }
}

