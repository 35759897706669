.projetBox{

    padding-top: 4.03rem;
    overflow-y: hidden;
    position: relative;

    button{
        background-color: transparent;
        position: relative;
        flex-shrink: 0;
        margin-top: 1rem;
        flex-basis: 3rem;
        width: 99%;
        background-color: transparent; //
        border: none;
        transition: all 0.5s ease;
        // filter: drop-shadow(0px 0px 1px white inset);

        @media(max-width:425px){
            margin-top: 2rem;
            flex-basis: 2rem;
        }

        span.projetName{
            font-family: inconsolata;
            font-size: 1.5rem;
            opacity: 0;
            color: black;
            text-shadow: 0px 0px 1px black;

            @media(max-width:425px){
                font-size: 1.2rem;
            }
        }
    }

    .buttonProjet{
        &:hover{
            cursor: pointer;
            background-color: rgb(250, 250, 250) !important;
        }
    }

    .buttonProjetSelected{
        background-color: rgb(250, 250, 250) !important;

        &:hover{
            cursor: pointer;
        }
    }
    .buttonProjetUnselected{
        animation:  opacity-out 0.3s ease forwards,
                    display-hidden 0.1s 0.3s ease forwards !important;
    }
}