.circuitCenter{
    width: 5rem;
    height: 5rem;
    // outline: 0.4rem solid rgb(255, 255, 255);
    // background-color: rgba(52, 106, 181, 0.531);
    opacity: 0;
    animation: opacity-in 0.5s 0.5s linear forwards;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width:425px){
        width: 10vw;
        height: 10vw;
    }

    .circle{
        position: absolute;
        
        width: 7rem;
        height: 7rem;
        background-color: rgb(75, 75, 75);
        border-radius: 100%;
        animation: resetCircle 0.3s linear forwards, pulseCircle 3s 0.3s ease-in-out infinite;
        box-shadow: 0px 0px 10px 5px rgb(144, 144, 144);

        @media(max-width:425px){
            width: 15vw;
            height: 15vw;
        }
    }

    .hyperActivationCircle{
        position: absolute;
        width: 7rem;
        height: 7rem;
        background-color: rgb(255, 255, 255);
        border-radius: 100%;
        box-shadow: 0px 0px 20px 10px rgb(255, 255, 255);
        animation: reduceSize 0.5s linear forwards, changeColor 0.1s linear forwards;

        @media(max-width:425px){
            width: 15vw;
            height: 15vw;
        }
    }
}

@keyframes resetCircle{
    from{box-shadow: 0px 0px 100px 80px rgb(255, 255, 255);}
    to{box-shadow: 0px 0px 10px 5px rgb(144, 144, 144);}
}

@keyframes pulseCircle{
    0%{
        box-shadow: 0px 0px 10px 5px rgb(144, 144, 144);
    }
    50%{
        box-shadow: 0px 0px 20px 10px rgb(144, 144, 144);
    }
    100%{
        box-shadow: 0px 0px 10px 5px rgb(144, 144, 144);
    }
}

@keyframes reduceSize{
    to{box-shadow: 0px 0px 0px 0px rgb(255, 255, 255);}
}

@keyframes changeColor{
    to{box-shadow: 0px 0px 100px 80px rgb(237, 237, 237);}
}









svg.svgCircuit{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // background-color: rgba(255, 0, 0, 0.115); //

    circle{
        padding: 1rem;
        position: relative;
        // fill: rgb(49, 49, 49);
        stroke: rgb(49, 49, 49);
        stroke-width: 2;
    }

    polyline.circuit{
        stroke-linecap: round;
        position: relative;
        fill: transparent;
        stroke: rgba(0, 0, 0, 0.519);
        stroke-width: 2;
    }

    polyline.impulse{
        stroke-linecap: round;
        position: relative;
        fill: transparent;
        // stroke: rgb(0, 0, 0);
        // stroke-width: 2;
    }
}